var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "", disabled: _vm.disabled },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "div",
                  _vm._g(_vm._b({}, "div", attrs, false), on),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v(
              "Video Library " + _vm._s(_vm.isCreate ? "upload" : "delete")
            ),
          ]),
          _c(
            "v-card-text",
            [
              _vm.isCreate
                ? [
                    !_vm.start
                      ? [
                          _c("p", [
                            _vm._v(
                              " Please select if you would want to keep the uploaded video among the files or just want to move the video to the library and remove it from the files. "
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { "hide-details": "" },
                              model: {
                                value: _vm.deleteAfetrUpload,
                                callback: function ($$v) {
                                  _vm.deleteAfetrUpload = $$v
                                },
                                expression: "deleteAfetrUpload",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "Remove from files",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "Keep among files",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm.start
                      ? [
                          _c("p", [
                            _vm._v(
                              "Video upload has started, see the progress below:"
                            ),
                          ]),
                          _vm.vimeoUploadProgress < 100 || !_vm.video
                            ? [
                                _c("v-progress-linear", {
                                  attrs: { indeterminate: "" },
                                }),
                                _vm.videos.length > 1
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-2 text-center" },
                                      [
                                        _vm._v(
                                          "Video uploaded: " +
                                            _vm._s(_vm.vimeoVideosFinised) +
                                            " / " +
                                            _vm._s(_vm.videos.length)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm.vimeoUploadProgress === 100 && _vm.video
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-center align-center",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { large: "", color: "success" } },
                                    [_vm._v("done")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "mb-4": _vm.videos.length === 1,
                                      },
                                    },
                                    [
                                      _vm.videos.length > 1
                                        ? _c("span", [_vm._v("ALL")])
                                        : _vm._e(),
                                      _vm._v(" DONE "),
                                    ]
                                  ),
                                  _vm.videos.length === 1 && _vm.video
                                    ? _c("vimeo-embed-code-btn", {
                                        attrs: { video: _vm.video },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ]
                : [
                    _c("p", [
                      _vm._v(
                        "Video delete has started, see the progress below:"
                      ),
                    ]),
                    _vm.vimeoUploadProgress < 100
                      ? [
                          _c("v-progress-linear", {
                            attrs: { indeterminate: "" },
                          }),
                          _vm.videos.length > 1
                            ? _c("div", { staticClass: "mt-2 text-center" }, [
                                _vm._v(
                                  "Video deleted: " +
                                    _vm._s(_vm.vimeoVideosFinised) +
                                    " / " +
                                    _vm._s(_vm.videos.length)
                                ),
                              ])
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm.vimeoUploadProgress === 100
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-center align-center",
                            staticStyle: { width: "100%", height: "100%" },
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { large: "", color: "success" } },
                              [_vm._v("done")]
                            ),
                            _c("div", [
                              _vm.videos.length > 1
                                ? _c("span", [_vm._v("ALL")])
                                : _vm._e(),
                              _vm._v(" DONE "),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
            ],
            2
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", text: "" },
                  on: { click: _vm.dismiss },
                },
                [_vm._v("Close")]
              ),
              _vm.isCreate && _vm.start
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        to: { name: "vimeo-videos" },
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("Go to Videos")]
                  )
                : _vm._e(),
              _vm.isCreate && !_vm.start
                ? _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: { click: _vm.crudCall },
                    },
                    [_vm._v("Ok")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }